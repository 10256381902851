import { graphql } from "gatsby";
import * as React from "react";
import '@fitplan/web-components/dist/esm/index.css';
import "../assets/css/_global.css";
import "../assets/css/markDown.css"
import Layout from "../components/layout";
import "../config.css";
import "../index.css";

const IndexPage = props => {
  const faviconTitle = props?.data?.site?.siteMetadata?.title
  const {
    data: { contentCollection, header, cookieConsent, siteWideMeta , taggedPageListCollection },
  } = props
  const content = contentCollection.edges[0].node

  const siteData = siteWideMeta?.edges?.[0]?.node

  return (
    <Layout
      header={header}
      content={content}
      cookieConsent={cookieConsent}
      faviconTitle={faviconTitle}
      siteWideMeta={siteData}
      taggedPageListCollection={taggedPageListCollection?.edges}
    />
  )
}

export default IndexPage

export const layoutQuery = graphql`
  query ($id: String! $navbarId: String!) {
    site {
      siteMetadata {
        title
      }
    }
    siteWideMeta: allContentfulSite(
      limit: 1000
      filter: {
        node_locale: { eq: "en-US" }
        title: { ne: "Sample - DO NOT USE OR DELETE" }
      }
    ) {
      edges {
        node {
          id
          __typename
          metaAuthor
          metaThemeColor
          metaCreator
          metaSiteName
          metaGoogleBot
          metaMsnBot
          metaMsTileColor
          facebookAppId
          facebookDomainVerification
          metaMobileWebAppTitle
          metaApplicationName
          node_locale
          showCookieConsent
          gaMeasurementId
          gtmId
        }
      }
    }
    header: contentfulNavigator(id: { eq: $navbarId }) {
      ...Header
    }
    contentCollection: allContentfulPage(filter: { id: { eq: $id } }) {
      edges {
        node {
          ...Page
        }
      }
    }
    cookieConsent: contentfulCookieConsent {
      ...CookieConsent
    }
    taggedPageListCollection: allContentfulPage(filter: { tags: { ne: null } }) {
      edges {
        node {
          ...Page
        }
      }
    }
  }
`